<template>
  <v-card>
    <v-container>
      <v-row class="mt-n7 mb-n6 mr-16">
        <v-col cols="7">
          <v-card-title>
            <span class="pl-1 primary--text">{{ title }}</span>
          </v-card-title>
        </v-col>
        <v-col cols="5" class="py-0 mt-8 ml-n9">
          <v-tooltip top max-width="20%">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">
                {{ infoIcon }}
              </v-icon>
            </template>
            <span>
              Orden en el cual se aplicaran los topes. Para más información consulte la ayuda
            </span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="ordenItems"
          :hide-default-footer="true"
          class="elevation-1"
          dense
        >
        </v-data-table>
        <v-card-actions class="p-0 mt-3">
          <v-spacer></v-spacer>
          <v-btn outlined @click="closeModal" class="mb-n4"> Cerrar </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-container>
  </v-card>
</template>
<script>
import enums from "@/utils/enums/index.js";
export default {
  name: "OrdenAplicacionTopes",
  data() {
    return {
      title: "Orden de aplicación",
      infoIcon: enums.icons.SNB_INFO,
      ordenItems: [
        { id: 1, convenioNom: "Convenio", planNom: "Plan" },
        { id: 2, convenioNom: "Todos", planNom: "Plan" },
        { id: 3, convenioNom: "Convenio", planNom: "Todos" },
        { id: 4, convenioNom: "Todos", planNom: "Todos" },
      ],
      headers: [
        {
          text: "Convenio",
          align: "start",
          value: "convenioNom",
          sortable: false,
        },
        {
          text: "Plan",
          align: "start",
          value: "planNom",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    closeModal() {
      this.$emit("closeOrdenAplicacion");
    },
  },
};
</script>
<style scoped>
</style>