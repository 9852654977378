<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0 pt-0"
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <v-row>
          <v-col cols="6" md="8" class="pt-0">
            <PageHeader :title="title" />
          </v-col>
          <v-col v-if="canConfig" cols="6" md="4" class="pt-0">
            <v-tooltip left class="pt-10">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="to-right"
                  v-on="on"
                  icon
                  v-bind="attrs"
                  @click="goToConfigParamAutorizac()"
                  ><v-icon size="30px">{{ settingsIcon }}</v-icon>
                </v-btn>
              </template>
              <span
                >Acceder a configuración de parámetros de topes en
                autorizaciones</span
              >
            </v-tooltip>
          </v-col>
        </v-row>
        <v-card class="mx-auto mb-4 pt-0" max-width="1200" outlined>
          <v-container class="pt-0">
            <v-row>
              <v-col cols="10" class="py-0" align="left">
                <FiltersSelected
                  :filters="filtersApplied"
                  v-if="!showFilters"
                />
              </v-col>
              <v-col
                cols="12"
                md="2"
                align="right"
                align-self="center"
                class="py-0 mt-2 mb-2"
              >
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="primary--text"
                      @click.stop="showFilters = !showFilters"
                    >
                      {{ closeFiltersIcon }}
                    </v-icon>
                  </template>
                  <span>{{
                    !showFilters ? "Mostrar filtros" : "Ocultar filtros"
                  }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-expand-transition mode="out-in">
              <v-form
                v-show="showFilters"
                ref="filters-form"
                id="filters-form"
                v-model="isFormValid"
                @submit.prevent="applyFilters()"
              >
                <!-- Filtros -->
                <v-row>
                  <!-- Convenios -->
                  <v-col cols="12" md="4" sm="4" class="py-0">
                    <v-autocomplete
                      v-model="conveniosSelect"
                      :items="conveniosItems"
                      label="Convenio"
                      item-text="value"
                      item-value="id"
                      outlined
                      clearable
                      dense
                      @change="setPlanesByConvenios()"
                      return-object
                    >
                    </v-autocomplete>
                  </v-col>
                  <!-- planes -->
                  <v-col cols="12" md="4" sm="4" class="py-0">
                    <v-autocomplete
                      v-model="planesSelected"
                      :items="planesItems"
                      item-text="value"
                      item-value="id"
                      label="Plan"
                      outlined
                      clearable
                      dense
                      return-object
                    >
                    </v-autocomplete>
                  </v-col>
                  <!-- Nomenclador -->
                  <v-col cols="12" md="4" sm="4" class="py-0">
                    <v-autocomplete
                      v-model="tipoNomencladorSelected"
                      :items="tipoNomencladorItems"
                      item-text="value"
                      item-value="id"
                      autocomplete="on"
                      label="Tipo nomenclador"
                      outlined
                      clearable
                      dense
                      return-object
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <!-- codigo -->
                  <v-col cols="12" md="4" sm="4" class="py-0">
                    <v-text-field
                      v-model.trim="codigoSelect"
                      outlined
                      clearable
                      dense
                      label="Código de práctica"
                      v-mask="'#########'"
                      :rules="
                        (conveniosSelect == null &&
                          planesSelected == null &&
                          tipoNomencladorSelected == null) ||
                        tipoNomencladorSelected
                          ? rules.required
                          : []
                      "
                    ></v-text-field>
                  </v-col>
                  <!-- Boton buscar codigo desde -->
                  <v-col cols="12" md="1" sm="1" class="py-0">
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-on="on"
                          v-bind="attrs"
                          absolute
                          fab
                          small
                          color="primary"
                          :disabled="tipoNomencladorSelected == null"
                          @click="openModalBusquedaCodigos()"
                        >
                          <v-icon>{{ searchIcon }}</v-icon>
                        </v-btn>
                      </template>
                      <span
                        >Buscar "código desde" a través de la descripción</span
                      >
                    </v-tooltip>
                  </v-col>
                  <v-col cols="12" md="7" sm="7" align="right">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="mr-2"
                          v-bind="attrs"
                          v-on="on"
                          size="28"
                          @click="resetForm"
                        >
                          {{ clearFiltersIcon }}
                        </v-icon>
                      </template>
                      <span>Limpiar filtros</span>
                    </v-tooltip>
                    <v-btn
                      color="primary"
                      elevation="2"
                      small
                      :disabled="!isFormValid"
                      type="submit"
                      form="filters-form"
                    >
                      Aplicar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-expand-transition>
          </v-container>
        </v-card>
        <v-card>
          <v-data-table
            :headers="headers"
            :items="itemsTopes"
            class="elevation-1"
            :search="search"
            :loading="isLoading"
            show-expand
            :expanded.sync="expanded"
            item-key="tautoBenId"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-row>
                  <v-col cols="5" md="4">
                    <v-text-field
                      v-model="search"
                      :append-icon="searchIcon"
                      label="Buscar"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3" md="3" align="end">
                    <v-btn
                      color="primary"
                      style="text-transform: none"
                      @click="openModalCodigoSinConfigurar()"
                    >
                      Códigos sin configurar
                    </v-btn>
                  </v-col>
                  <v-col
                    v-if="canImport"
                    :cols="canCreate && canImport ? 4 : 5"
                    :md="canCreate && canImport ? 4 : 5"
                    align="start"
                  >
                    <v-btn
                      color="primary"
                      style="text-transform: none"
                      @click="goToProcImportacion()"
                    >
                      Procesos de importación
                    </v-btn>
                  </v-col>
                  <v-col
                    :cols="canCreate && canImport ? 1 : 5"
                    :md="canCreate && canImport ? 1 : 5"
                    align="end"
                    v-if="canCreate"
                  >
                    <v-btn
                      color="primary"
                      @click="openModalEdit()"
                      class="to-right"
                    >
                      Nuevo
                    </v-btn>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left v-if="canEdit">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="openModalEdit(item)"
                  >
                    {{ editIcon }}
                  </v-icon>
                </template>
                <span>Editar tope</span>
              </v-tooltip>
              <v-tooltip left v-if="canDelete">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="deleteTope(item.tautoBenId)"
                  >
                    {{ deleteIcon }}
                  </v-icon>
                </template>
                <span>Eliminar tope</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.data-table-expand`]="{ item, isExpanded }">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    @click="handleExpansion(item, isExpanded)"
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    >{{
                      isExpanded ? "mdi-account-check" : "mdi-account"
                    }}</v-icon
                  >
                </template>
                <span>Usuarios</span>
              </v-tooltip>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <strong>Fecha alta:</strong>
                {{ item.fechaAlta }}
                <strong style="padding-left: 80px">Usuario alta:</strong>
                {{ item.usuAlta }}
                <strong style="padding-left: 80px">Fecha modificación:</strong>
                {{ item.fechaModi }}
                <strong style="padding-left: 80px"
                  >Usuario modificación:</strong
                >
                {{ item.usuModi }}
              </td>
            </template>
          </v-data-table>
        </v-card>
        <v-row>
          <v-col cols="2" class="py-3 mr-n7">
            <v-tooltip top max-width="40%">
              <template v-slot:activator="{ attrs }">
                <v-btn
                  color="primary"
                  class="fontsize"
                  v-bind="attrs"
                  style="text-transform: none"
                  outlined
                  :loading="loadingExportAllTopes"
                  @click="exportAllTopes"
                >
                  Exportar todo
                </v-btn>
              </template>
            </v-tooltip>
          </v-col>
          <v-col cols="6" class="py-3 mr-6">
            <v-tooltip top max-width="40%">
              <template v-slot:activator="{ attrs }">
                <v-btn
                  color="primary"
                  class="fontsize"
                  v-bind="attrs"
                  style="text-transform: none"
                  outlined
                  @click="openModalOrdenAplicacion"
                >
                  Orden de aplic.
                </v-btn>
              </template>
            </v-tooltip>
          </v-col>
          <v-col cols="4" align="right" class="py-3">
            <v-tooltip top max-width="40%">
              <template v-slot:activator="{ attrs }">
                <v-btn
                  color="primary"
                  class="fontsize"
                  v-bind="attrs"
                  outlined
                  :disabled="itemsTopes.length == 0"
                  @click="exportExcelModelo"
                >
                  Exportar detalle
                </v-btn>
              </template>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="text-right pt-3 pr-0"
        v-if="showIcon"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <v-dialog
      v-if="openModalEditBan"
      v-model="openModalEditBan"
      max-width="70%"
      @keydown.esc="closeAndReload"
      persistent
    >
      <EditTopesGrupoPracticas
        :topesGralesItem="topesGralesItem"
        @closeAndReload="closeAndReload"
      ></EditTopesGrupoPracticas>
    </v-dialog>
    <v-dialog
      v-if="openModalCodigoSinConfigurarExc"
      v-model="openModalCodigoSinConfigurarExc"
      max-width="80%"
      @keydown.esc="closeAndReload"
      persistent
    >
      <CodigoSinConfigurarExcel
        :topesGralesItem="topesGralesItem"
        @closeAndReload="closeAndReload"
      ></CodigoSinConfigurarExcel>
    </v-dialog>
    <v-dialog
      v-if="openModalOrdenAplicacionExc"
      v-model="openModalOrdenAplicacionExc"
      max-width="35%"
      @keydown.esc="closeOrdenAplicacion"
      persistent
    >
      <OrdenAplicacionTopes
        @closeOrdenAplicacion="closeOrdenAplicacion"
      ></OrdenAplicacionTopes>
    </v-dialog>
    <v-dialog
      v-model="modalBusquedaCodigosBan"
      v-if="modalBusquedaCodigosBan"
      @keydown.esc="modalBusquedaCodigosBan = false"
      max-width="50%"
      persistent
    >
      <BusquedaCodigoNomenclador
        @toggleModalBusquedaCodigo="modalBusquedaCodigosBan = false"
        @findCodigo="findCodigo"
        :nomencladorObject="tipoNomencladorSelected"
      ></BusquedaCodigoNomenclador>
    </v-dialog>
    <DeleteDialog
      :titleProp="titleDelete"
      :maxWidth="'25%'"
      :isLoading="loadingBtnDelete"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDelete()"
    />
  </v-container>
</template>

<script>
import Ayuda from "@/components/shared/Ayuda.vue";
import BusquedaCodigoNomenclador from "@/components/modules/afiliaciones/afiliados/BusquedaCodigoNomenclador.vue";
import GoBackBtn from "@/components/shared/GoBackBtn";
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import { mask } from "vue-the-mask";
import FiltersSelected from "@/components/shared/FiltersSelected";
import EditTopesGrupoPracticas from "@/components/modules/prestaciones/configuracion/EditTopesGenerales.vue";
import CodigoSinConfigurarExcel from "@/components/modules/prestaciones/configuracion/CodigoSinConfigurarExcel.vue";
import OrdenAplicacionTopes from "@/components/modules/prestaciones/configuracion/OrdenAplicacionTopes.vue";
import DeleteDialog from "@/components/shared/DeleteDialog";
import helpersExcel from "@/utils/helpers/importExportExcel";

export default {
  name: "TopesGenerales",
  directives: { mask },
  components: {
    Ayuda,
    GoBackBtn,
    BusquedaCodigoNomenclador,
    PageHeader,
    FiltersSelected,
    DeleteDialog,
    EditTopesGrupoPracticas,
    CodigoSinConfigurarExcel,
    OrdenAplicacionTopes
  },
  data: () => ({
    rules: rules,
    routeToGo: "ConfiguracionPrestaciones",
    title: enums.titles.TOPES_GRALES,
    settingsIcon: enums.icons.SETTINGS,
    titleDelete: enums.titles.DELETE_TOPE_GRAL,
    searchIcon: enums.icons.SEARCH,
    optionCode: enums.webSiteOptions.TOPES_GENERALES,
    editIcon: enums.icons.EDIT,
    deleteIcon: enums.icons.DELETE,
    closeFiltersIcon: enums.icons.CLOSE_FILTERS,
    clearFiltersIcon: enums.icons.CLEAR_FILTERS,
    showIcon: true,
    loadingExportAllTopes: false,
    showHelp: false,
    isFormValid: false,
    showExpand: false,
    canConfig: false,
    search: "",
    //filters
    tipoNomencladorSelected: null,
    expanded: [],
    tipoNomencladorItems: [],
    topesGralesItem: {},
    conveniosSelect: null,
    conveniosItems: [],
    planesSelected: null,
    planesItems: [],
    codigoSelect: null,
    canCreate: false,
    canImport: false,
    canDelete: false,
    canEdit: false,
    loadingBtnDelete: false,
    openModalEditBan: false,
    openModalCodigoSinConfigurarExc: false,
    openModalOrdenAplicacionExc: false,
    modalBusquedaCodigosBan: false,
    showDeleteModal: false,
    showFilters: true,
    isLoading: false,
    itemsTopes: [],
    filtersApplied: [],
    headers: [
      {
        text: "Convenio",
        align: "start",
        value: "os.value",
        sortable: false
      },
      {
        text: "Plan",
        align: "start",
        value: "plan.value",
        sortable: false
      },
      {
        text: "Sexo",
        align: "center",
        value: "sexo",
        sortable: false
      },
      {
        text: "Edad desde",
        value: "edadDesde",
        align: "end",
        sortable: false
      },
      {
        text: "Edad hasta",
        value: "edadHasta",
        align: "end",
        sortable: false
      },
      {
        text: "Nomenclador",
        value: "nomenclador.value",
        sortable: false
      },
      {
        text: "Código desde",
        value: "codigoDesde",
        sortable: false
      },
      {
        text: "Código hasta",
        value: "codigoHasta",
        sortable: false
      },
      {
        text: "Agrupación",
        value: "agrupra.value",
        sortable: false
      },
      {
        text: "Grupo",
        value: "gru.value",
        sortable: false
      },
      {
        text: "Vigencia",
        value: "vigencia",
        sortable: false
      },
      {
        text: "Tope",
        value: "tope",
        align: "end",
        sortable: false
      },
      {
        text: "Días",
        value: "dias",
        sortable: false
      },
      { text: "Acciones", value: "actions", sortable: false, align: "end" },
      { text: "", value: "data-table-expand", align: "end" }
    ],
    idToDelete: null
  }),
  async created() {
    await this.$store.dispatch(
      "user/updateFrequencyByOptionCode",
      this.optionCode
    );
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.setPermisos();
    this.setSelects();
  },
  methods: {
    ...mapActions({
      getConvenios: "aportes/fetchConvenios",
      getPlanesByConvenio: "configAfiliaciones/getPlanesByConvenio",
      getPlanesTaxonomy: "configAfiliaciones/getPlanesTaxonomy",
      getNomencladores: "afiliaciones/getNomencladores",
      getTopesGenerales: "prestaciones/getTopesGenerales",
      deleteTopesGenerales: "prestaciones/deleteTopesGenerales",
      getAllTopesToExport: "prestaciones/getAllTopesToExport",
      setAlert: "user/setAlert"
    }),
    setPermisos() {
      this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions.ELIMINAR_TOPE_GRAL:
            this.canDelete = true;
            break;
          case enums.modules.adminSistema.permissions.EDITAR_TOPE_GRAL:
            this.canEdit = true;
            break;
          case enums.modules.adminSistema.permissions.NUEVO_TOPE_GRAL:
            this.canCreate = true;
            break;
          case enums.modules.adminSistema.permissions.CONFIG_TOPE_GRAL:
            this.canConfig = true;
            break;
          case enums.modules.adminSistema.permissions
            .IR_A_PROCESO_IMPORTACION_TOPES:
            this.canImport = true;
            break;
          default:
            break;
        }
      });
    },
    goToConfigParamAutorizac() {
      this.$router.push({
        name: "ParametrosTopesAuto"
      });
    },
    async setSelects() {
      const planes = await this.getPlanesTaxonomy();
      this.planesItems = planes;
      const convenios = await this.getConvenios();
      this.conveniosItems = convenios;
      const nomencladores = await this.getNomencladores();
      this.tipoNomencladorItems = nomencladores;
    },
    openModalEdit(item) {
      this.topesGralesItem = item;
      this.openModalEditBan = true;
    },
    openModalCodigoSinConfigurar() {
      this.openModalCodigoSinConfigurarExc = true;
    },
    openModalOrdenAplicacion(){
      this.openModalOrdenAplicacionExc = true;
    },
    async exportAllTopes() {
      try {
        this.loadingExportAllTopes = true;
        const res = await this.getAllTopesToExport();
        if (res.length == 0) {
          this.loadingExportAllTopes = false;
          this.setAlert({
            type: "warning",
            message: "No hay topes para exportar."
          });
        }
        this.exportExcelWithAllTopes(res);
      } catch {}
    },
    customizeFiltersApplied() {
      this.filtersApplied = [];
      if (this.conveniosSelect) {
        this.filtersApplied.splice(0, 1, {
          key: "conveniosSelect",
          label: "Convenio",
          model: this.conveniosSelect.value
        });
      }
      if (this.planesSelected) {
        this.filtersApplied.splice(1, 1, {
          key: "planesSelected",
          label: "Plan",
          model: this.planesSelected.value
        });
      }
      if (this.tipoNomencladorSelected) {
        this.filtersApplied.splice(2, 1, {
          key: "tipoNomencladorSelected.value",
          label: "Tipo nomenclador",
          model: this.tipoNomencladorSelected.value
        });
      }
      if (this.codigoSelect !== null) {
        this.filtersApplied.splice(3, 1, {
          key: "codigoSelect",
          label: "Código de práctica",
          model: this.codigoSelect.toString()
        });
      }
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    handleExpansion(item, state) {
      const itemIndex = this.expanded.indexOf(item);
      state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item);
    },
    async setPlanesByConvenios() {
      if (this.conveniosSelect == null || this.conveniosSelect.id == 0) {
        this.planesItems = [];
        const allPlanes = await this.getPlanesTaxonomy();
        this.planesItems = allPlanes;
      } else {
        this.planesItems = [];
        const planes = await this.getPlanesByConvenio([
          this.conveniosSelect.id
        ]);
        this.planesItems = planes;
      }
    },
    async applyFilters() {
      this.isLoading = true;
      this.showFilters = false;
      this.customizeFiltersApplied();
      const filters = {
        osId: this.conveniosSelect?.id,
        planId: this.planesSelected?.id,
        nomencladorId: this.tipoNomencladorSelected?.id,
        codigoPractica: this.codigoSelect ?? null
      };
      try {
        const response = await this.getTopesGenerales(filters);
        this.itemsTopes = response;
      } catch {}
      this.isLoading = false;
    },
    findCodigo(codigoSelected) {
      this.codigoSelect = codigoSelected.nomCod;
    },
    openModalBusquedaCodigos() {
      this.modalBusquedaCodigosBan = true;
    },
    deleteTope(id) {
      this.showDeleteModal = true;
      this.idToDelete = id;
    },
    async confirmDelete() {
      this.loadingBtnDelete = true;
      const response = await this.deleteTopesGenerales(this.idToDelete);
      if (response.status === 200) {
        this.showDeleteModal = false;
        this.setAlert({
          type: "success",
          message: "Tope eliminado con éxito"
        });
        this.applyFilters();
      }
      this.loadingBtnDelete = false;
    },
    goToProcImportacion() {
      this.$router.push({
        name: "ProcesosImportacionTopesGenerales"
      });
    },
    exportExcelWithAllTopes(allTopes) {
      let result = [];
      allTopes?.forEach(x => {
        result.push({
          ["Convenio"]: x.os,
          ["Plan"]: x.plan,
          ["Sexo"]: x.sexo,
          ["Edad desde"]: x.edadDesde,
          ["Edad hasta"]: x.edadHasta,
          ["Nomenclador"]: x.nomenclador == null ? "" : x.nomenclador,
          ["Codigo desde"]: x.codigoDesde == null ? "" : x.codigoDesde,
          ["Codigo hasta"]: x.codigoHasta == null ? "" : x.codigoHasta,
          ["Agrupacion"]: x.agrupra == null ? "" : x.agrupra,
          ["Grupo"]: x.gru == null ? "" : x.gru,
          ["Vigencia"]: x.vigenciaToShow,
          ["Topes"]: x.tope,
          ["Dias"]: x.dias,
          ["Tipo de autorizacion"]: x.tipoAutorizacion,
          ["Tope año calendario"]: x.anioCalendario,
          ["Controlar codigo rango"]: x.controlarCodRango,
          ["Aplicar tope por grupo familiar"]: x.aplicarTopePorGrupo
        });
      });
      let formato = {
        filas: result,
        hoja: "Resultado"
      };
      helpersExcel.excelExport(formato, "Topes generales");
      this.loadingExportAllTopes = false;
    },
    exportExcelModelo() {
      let result = [];
      this.itemsTopes?.forEach(x => {
        result.push({
          ["Convenio"]: x.os.value,
          ["Plan"]: x.plan.value,
          ["Sexo"]: x.sexo,
          ["Edad desde"]: x.edadDesde,
          ["Edad hasta"]: x.edadHasta,
          ["Nomenclador"]: x.nomenclador == null ? "" : x.nomenclador.value,
          ["Codigo desde"]: x.codigoDesde == null ? "" : x.codigoDesde,
          ["Codigo hasta"]: x.codigoHasta == null ? "" : x.codigoHasta,
          ["Agrupacion"]: x.agrupra == null ? "" : x.agrupra.value,
          ["Grupo"]: x.gru == null ? "" : x.gru.value,
          ["Vigencia"]: x.vigencia,
          ["Topes"]: x.tope,
          ["Dias"]: x.dias,
          ["Tipo de autorizacion"]: x.tipoAutorizacion.value,
          ["Tope año calendario"]: x.anioCalendario ? "SI" : "NO",
          ["Controlar codigo rango"]: x.controlarCodRango ? "SI" : "NO",
          ["Aplicar tope por grupo familiar"]: x.aplicarTopePorGrupo
            ? "SI"
            : "NO"
        });
      });
      let formato = {
        filas: result,
        hoja: "Resultado"
      };
      helpersExcel.excelExport(formato, "Topes generales");
    },
    resetForm() {
      this.conveniosSelect = null;
      this.planesSelected = null;
      this.tipoNomencladorSelected = null;
      this.codigoSelect = null;
    },
    closeAndReload() {
      this.openModalEditBan = false;
      this.openModalCodigoSinConfigurarExc = false;
      this.applyFilters();
    },
    closeOrdenAplicacion(){
      this.openModalOrdenAplicacionExc = false
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    }
  }
};
</script>
